import { SITE_URL } from "_config/index";

const siteURL = `${ SITE_URL }/`;

export const getSEOContent = ( tag, props ) => {

	const { title, desc, endpoint, thumbnail } = props;

	if ( tag.content ) {
		let content = tag.content;

		if ( tag.content.includes ( "~Title" ) ) {
			content = content.replace ( "~Title~", title );
		}

		if ( ( tag.content.includes ( "~Description~" ) ) ) {
			content = content.replace ( "~Description~", desc );
		}

		if ( ( tag.content.includes ( "~Endpoint~" ) ) ) {
			content = content.replace ( "~Endpoint~", endpoint.trim () );
		}

		if ( ( tag.content.includes ( "~Thumbnail~" ) ) ) {
			content = content.replace ( "~Thumbnail~", thumbnail );
		}

		if ( ( tag.content.includes ( "~SiteURL~" ) ) ) {
			content = content.replace ( "~SiteURL~", siteURL.trim () );
		}

		return content.trim ();

	}

};

/**
 * Description placeholder
 *
 * @export
 * @param {Object} param
 * @param {string} param.source
 * @param {string} param.medium
 * @param {string} param.campaign
 * @returns {string}
 */
export function getUtmParams ( { source, medium, campaign } ) {
	const params = new URLSearchParams ( {
		utm_source   : source,
		utm_medium   : medium,
		utm_campaign : campaign
	} );

	return params.toString ();
}

/**
 *
 * @param {string} baseUrl
 * @param {Record<string, string>} [queryParams={}]
 * @returns {Promise<string>}
 */
export const createUrl = ( baseUrl, queryParams = {} ) => {
	const url = new URL ( baseUrl );

	Object.entries ( queryParams ).forEach ( ( [ key, value ] ) => {
		url.searchParams.append ( `utm_${ key }`, value );
	} );

	return url.toString ();
};

export const getSEOTitle = ( titleContent, props ) => {

	const { title, desc, endpoint, thumbnail } = props;

	if ( titleContent ) {
		let updatedTitle = titleContent;

		if ( updatedTitle.includes ( "~Title" ) ) {
			updatedTitle = updatedTitle.replace ( "~Title~", title );
		}

		if ( ( updatedTitle.includes ( "~Description~" ) ) ) {
			updatedTitle = updatedTitle.replace ( "~Description~", desc );
		}

		if ( ( updatedTitle.includes ( "~Endpoint~" ) ) ) {
			updatedTitle = updatedTitle.replace ( "~Endpoint~", endpoint );
		}

		if ( ( updatedTitle.includes ( "~Thumbnail~" ) ) ) {
			updatedTitle = updatedTitle.replace ( "~Thumbnail~", thumbnail );
		}

		if ( ( updatedTitle.includes ( "~SiteURL~" ) ) ) {
			updatedTitle = updatedTitle.replace ( "~SiteURL~", siteURL );
		}

		return updatedTitle.trim ();

	}

};

