import Icon       from "_components/atoms/icon";
import ToolTip    from "_components/atoms/tooltip";
import Typography from "_components/atoms/typography";

import useStyles from "./styles";

const component = ( { active, iconMap, onClick, name, isSidebarMenuOpen, borderBottom, children, badge } ) => {
	const { classes } = useStyles ( { isSidebarMenuOpen }, { name: "navMenuItem" } );

	return (
		<div
			onClick = { onClick }
		>
			<ToolTip disable = { isSidebarMenuOpen }
				title           = { name }
			>
				<div>
					<div className = { `${ active ? classes.menuItemContainerActive : classes.menuItemContainer } ${ borderBottom ? "borderBottom" : "" }` }>
						<Icon
							sameInBothTheme
							className = { classes.menuItem }
							name      = { iconMap[active ? "active" : "inactive"] }
						/>

						<div className = { classes.textContainer }>
							<div className = { classes.textAndBadge }>
								<Typography
									className = { classes.menuName }
									color     = { active ? "primary" : "secondary" }
									title     = { name }
								/>

								{badge}
							</div>

							{children}

						</div>

					</div>

				</div>

			</ToolTip>
		</div>
	);
};

export default component;