import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => ( {
	menuItemContainerActive: {
		// marginBottom : "1rem",
		position     : "relative",
		display      : "flex",
		alignItems   : "center",
		padding      : "1.5rem 0",
		cursor       : "pointer",
		borderRadius : "0 1rem 1rem 0",

		"&::before": {
			width                   : "0.45rem",
			content                 : "''",
			display                 : "block",
			height                  : "100%",
			position                : "absolute",
			borderTopRightRadius    : "1rem",
			borderBottomRightRadius : "1rem",
			backgroundImage         : "linear-gradient(to bottom, #00c5ff 0%, #0076ff 100%)",
			marginRight             : "1rem"
		},
		[rooterMediaQuery.mobile]: {
			backgroundColor : theme.other.paletteNew.darkModeDarkBlue50,
			padding         : "1.6rem 0",
			borderRadius    : "0",
			marginBottom    : "unset"
		},
		"&:hover": {
			backgroundColor: props?.isSidebarMenuOpen ? ( theme.other.darkMode ? theme.other.paletteNew.darkModeDarkGrey : theme.other.paletteNew.secondaryLightGrey ) : "none"
		}

	},
	menuItemContainer: {
		// marginBottom : "1rem",
		display      : "flex",
		alignItems   : "center",
		padding      : "1.5rem 0",
		cursor       : "pointer",
		borderRadius : "0 1rem 1rem 0",

		[rooterMediaQuery.mobile]: {
			padding          : "1.6rem 0",
			marginBottom     : "unset",
			borderRadius     : "0",
			"&.borderBottom" : {
				borderBottom: "1px solid rgba(117, 117, 117, 0.2)"
			}
		},
		"&:hover": {
			backgroundColor: props?.isSidebarMenuOpen ? ( theme.other.darkMode ? theme.other.paletteNew.darkModeDarkGrey : theme.other.paletteNew.secondaryLightGrey ) : "none"
		}

	},
	menuItem: {
		height     : "2.8rem",
		width      : "2.8rem",
		minWidth   : "2.8rem",
		marginLeft : "1.4rem",
		cursor     : "pointer",

		[rooterMediaQuery.mobile]: {
			height   : "2.4rem",
			width    : "2.4rem",
			minWidth : "2.4rem"
		},

		[rooterMediaQuery.tablet]: {
			height     : "2.8rem",
			width      : "2.8rem",
			minWidth   : "2.8rem",
			marginLeft : "1rem"
		}

	},
	menuName: {
		textOverflow : "ellipsis",
		whiteSpace   : "nowrap",
		marginLeft   : "1rem",
		opacity      : props?.isSidebarMenuOpen ? "1" : "0",
		fontWeight   : "500",
		overflow     : "hidden",
		transition   : "0.2s"
	},

	textContainer: {
		display        : "flex",
		alignItems     : "center",
		justifyContent : "space-between",
		flex           : "1",
		paddingRight   : "2rem"
	},
	textAndBadge: {
		display    : "flex",
		alignItems : "center"
	}
} ), { name: "sidebarMenu" } );

