import Presentation from "./presentation";

const NavMenuItem = ( { active, iconMap, onClick, name, isSidebarMenuOpen, borderBottom, children, badge } ) => {
	return (
		<Presentation
			active            = { active }
			badge             = { badge }
			borderBottom      = { borderBottom }
			iconMap           = { iconMap }
			isSidebarMenuOpen = { isSidebarMenuOpen }
			name              = { name }
			onClick           = { onClick }
		>
			{children}

		</Presentation>
	);
};

export default NavMenuItem;