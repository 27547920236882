import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => ( {
	dialogContainer: {
		// maxWidth: "600px"

	},
	dialogPaper: {
		display         : "flex",
		flexDirection   : "column",
		alignItems      : "center",
		margin          : "2rem",
		borderRadius    : "10px",
		flex            : "unset",
		maxWidth        : "500px",
		// border          : "solid 1px #e7e7e7",
		backgroundColor : theme.other.colors.dialog.secondary
	},
	modalBody: {
		padding: "0"
	},
	logoutText: {
		color        : "rgba(0, 0, 0, 0.87)",
		fontWeight   : "500",
		fontSize     : "2rem",
		marginBottom : "1rem"
	},
	acceptButton: {
		width         : "100%",
		border        : "none !important",
		height        : "100%",
		padding       : "1.5rem",
		textTransform : "none",
		":hover"      : {
			border: "none"
		}
	},
	rejectButton: {
		width           : "100%",
		padding         : "1.5rem",
		marginLeft      : "0 !important",
		backgroundImage : "linear-gradient(to bottom,#126bd5,#439aff )",
		height          : "100%",
		textTransform   : "none",
		border          : "none",
		borderRadius    : "0",
		":hover"        : {
			border: "none"
		}
	},
	dialogContent: {
		display        : "flex",
		flexDirection  : "column",
		padding        : "4rem",
		alignItems     : "center",
		justifyContent : "center",
		textAlign      : "center",
		overflowY      : "hidden",
		// minWidth       : "45rem",
		height         : "15rem"
	},
	dialogActions: {
		display   : "flex",
		width     : "100%",
		padding   : "0",
		borderTop : `1px solid ${ theme.other.colors.border.primary }`
	},
	title: {
		fontSize      : "2rem",
		fontWeight    : "500",
		marginBottom  : "0.5em",
		textTransform : "capitalize"
	},
	acceptButtonText: {
		// color      : "#000",
		fontWeight : "500",
		fontSize   : "1.7rem"
	},
	rejectButtonText: {
		fontSize   : "1.7rem",
		border     : "none",
		fontWeight : "500",
		color      : theme.other.paletteNew.secondaryWhite
	}
} ) );
